<template>
    <div>
        <!-- User dashboard section -->
        <v-card>
            <v-card-title>
                <h3>Announcements</h3>
            </v-card-title>
        </v-card>
        <create-news class="mt-4 pa-4"></create-news>
    </div>
</template>

<script>
import CreateNews from '@/views/news/CreateNews'
import { mapGetters } from 'vuex'
export default {
    name: 'Announcements',
    components: {
        'create-news': CreateNews,
    },
    computed: {
        ...mapGetters({}), // Map Vuex getters to component's computed properties
    },
    mounted() {
        // this.showNewsCard();
    },
    data: () => ({}),
    methods: {},
}
</script>
