<template>
    <v-card>
        <v-card-title v-if="hideNews">
            <v-btn color="primary darken-1" text @click="getAllNews"
                >See last message posted</v-btn
            >
        </v-card-title>
        <v-list-item>
            <v-list-item-content>
                <!-- Sección para mostrar todas las noticias publicadas -->
                <section v-if="!hideNews">
                    <div v-if="allNews.length === 0">
                        <p style="font-weight:bold">No Message Posted</p>
                    </div>
                    <div v-else v-for="news in allNews" :key="news.id">
                        <v-card>
                            <v-btn
                                color="primary darken-1"
                                text
                                @click="toggleNews"
                                style="float: right"
                                >Close</v-btn
                            >
                            <h3 style="padding:10px">Current Announcement:</h3>
                            <!-- Edit button to open the editor with the news content -->
                            <p v-html="news.news_content"></p>
                            <p>
                                Date of expiration:
                                {{ formatDate(news.news_date_expiration) }}
                            </p>
                            <p>Announcement type: {{ news.news_type }}</p>
                            <v-btn
                                color="primary darken-1"
                                text
                                @click="editNews(news)"
                                >Edit</v-btn
                            >
                            <v-btn
                                color="primary darken-1"
                                text
                                @click="deleteNew(news.id)"
                                >Delete Message</v-btn
                            >
                        </v-card>
                    </div>
                </section>
            </v-list-item-content>
        </v-list-item>

        <!-- Input field for selecting the expiration date of the news -->
        <div>
            <v-menu
                v-model="showDatePicker"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedDate"
                        label="Select a expiration date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="datepicker"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="news_date_expiration"
                    @input="updateDate"
                    :min="tomorrow"
                ></v-date-picker>
            </v-menu>
        </div>
        <!-- Select field for choosing the type of news -->
        <div>
            <v-container>
                <v-row>
                    <v-col md="4">
                        <v-select
                            class="select"
                            v-model="news_type"
                            :items="news_types"
                            label="Select announcement type"
                            outlined
                            dense
                        ></v-select>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <section>
            <!-- Buttons for formatting the news content -->
            <div
                style="box-shadow: 0px 0px 5px rgba(0,0,0,0.5); margin-top: 10px;"
            >
                <v-btn
                    @click="bold"
                    style="margin-left: 10px; font-weight: bold; box-shadow: none; background: transparent;"
                    >Bold</v-btn
                >
                <v-btn
                    @click="italic"
                    style="margin-left: 10px;font-style: italic;box-shadow: none; background: transparent;"
                    >Italic</v-btn
                >
                <v-btn
                    @click="openModal()"
                    style="margin-left: 10px;box-shadow: none; background: transparent;"
                    >Link</v-btn
                >
                <v-btn
                    @click="makeUnOrderedList"
                    style="margin-left: 10px;font-style: italic;box-shadow: none; background: transparent;"
                >
                    <v-icon>
                        mdi-format-list-bulleted
                    </v-icon>
                </v-btn>
                <v-btn
                    @click="makeOrderedList"
                    style="margin-left: 10px;font-style: italic;box-shadow: none; background: transparent;"
                >
                    <v-icon>
                        mdi-format-list-numbered
                    </v-icon>
                </v-btn>
            </div>

            <!-- Contenteditable div for composing the news content -->
            <div
                contenteditable="true"
                ref="editor"
                class="editor"
                @input="updateNews()"
                id="editablecontent"
                style="padding-left:10px;padding-right:10px"
            ></div>
        </section>

        <!-- Button for publishing the news -->
        <div style="float: right;">
            <div style="float: right;">
                <!-- Show this button when not editing (creating new news) -->
                <v-btn
                    color="primary darken-1"
                    text
                    @click="createNews"
                    v-if="!editingNews"
                    >Publish</v-btn
                >
                <!-- Show this button when editing (updating existing news) -->
                <v-btn color="primary darken-1" text @click="NewEdit" v-else
                    >Update</v-btn
                >
            </div>
        </div>
        <!-- Snackbar for displaying success or error message -->
        <v-snackbar
            v-model="snackbar.show"
            :timeout="snackbar.timeout"
            :color="snackbar.color"
            >{{ snackbar.message }}</v-snackbar
        >
        <!-- Display the remaining characters allowed for the news content -->
        <div style="padding:10px;">
            {{ remainingCharacters }} characters remaining
        </div>

        <!-- Preview section for the news content -->
        <div style="display: flex; padding:14px; flex-direction: column;">
            <div class="news-preview">
                <div>
                    <p class="">
                        Announcement preview:
                    </p>
                </div>
                <div v-html="news_content"></div>
                <div>
                    <p class="">Date of expiration: {{ formattedDate }}</p>
                </div>
                <div>
                    <p class="">Announcement type: {{ news_type }}</p>
                </div>
            </div>
        </div>

        <v-menu
            v-model="showModal"
            max-width="600px"
            :close-on-content-click="false"
            :nudge-right="300"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
            <v-card>
                <v-card-title>Insert Link</v-card-title>
                <v-card-text>
                    <form>
                        <v-text-field v-model="url" label="URL"></v-text-field>
                        <v-text-field
                            v-model="anchorText"
                            label="Anchor Text"
                        ></v-text-field>
                    </form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="insertLink()">Insert</v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </v-card>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
    name: 'CreateNews',
    // Data object for storing the news content, expiration date, and remaining characters
    data() {
        return {
            remainingCharacters: 500,
            news_content: '',
            news_date_expiration: null,
            snackbar: {
                show: false,
                message: '',
                timeout: 3000,
                color: '',
            },
            allNews: [],
            showDatePicker: false,
            hideNews: false,
            showModal: false,
            url: '',
            anchorText: '',
            news_type: '',
            selection: null,
            editingNews: false,
            news_types: [
                { text: '📢 Urgent', value: '📢 Urgent' },
                { text: '📰 News', value: '📰 News' },
            ],
        }
    },

    mounted() {
        this.getAllNews()
    },
    watch: {
        allNews: function() {
            this.$forceUpdate()
        },
    },
    computed: {
        ...mapGetters({
            authUser: 'authUser',
        }),
        tomorrow() {
            return moment()
                .add(1, 'day')
                .format('YYYY-MM-DD')
        },
        news_query_vars: function() {
            if (this.$store.getters.userForPortalAccess.client) {
                return {
                    client: this.$store.getters.userForPortalAccess.client.id,
                }
            } else {
                return {}
            }
        },
        query_vars() {
            return {
                client_id: this.$store.getters.userForPortalAccess.client.id,
            }
        },

        formattedDate() {
            return this.news_date_expiration
                ? moment(this.news_date_expiration).format('MM-DD-YYYY')
                : null
        },
    },
    // Methods for formatting the news content and checking its length
    methods: {
        formatDate(date) {
            return date ? moment(date).format('MM-DD-YYYY') : null
        },
        extractLinks(html) {
            let doc = document.createElement('html')
            doc.innerHTML = html
            let links = doc.getElementsByTagName('a')
            let urls = []
            for (var i = 0; i < links.length; i++) {
                urls.push(links[i].length)
            }

            return urls
        },

        extractedContent(value) {
            let content = document.createElement('html')
            content.innerHTML = value
            content = content.textContent
            return content
        },

        getAllNews() {
            this.$apollo
                .query({
                    query: require('@/graphql/news/allNews.gql'),
                    variables: this.news_query_vars(),
                })
                .then(response => {
                    this.hideNews = false
                    this.snackbar.show = true
                    this.snackbar.message = 'All messages loaded'
                    this.snackbar.color = 'success'
                    this.allNews = response.data.allNews
                })
                .catch(error => {
                    this.error = error
                    this.snackbar.show = true
                    this.snackbar.message = 'Error to show last news'
                    this.snackbar.color = 'error'
                })
        },
        toggleNews() {
            this.hideNews = !this.hideNews
        },
        bold() {
            document.execCommand('bold', false, null)
        },
        italic() {
            document.execCommand('italic', false, null)
        },
        makeOrderedList() {
            document.execCommand('insertOrderedList', false, null)
        },
        makeUnOrderedList() {
            document.execCommand('insertUnOrderedList', false, null)
        },
        increaseFontSize() {
            document.execCommand('fontSize', false, '5')
        },
        decreaseFontSize() {
            document.execCommand('fontSize', false, '3')
        },

        openModal() {
            this.showModal = true
            this.selection = this.saveSelection()
            this.anchorText = window.getSelection().toString()
        },

        insertLink() {
            const editor = this.$refs.editor
            const link = document.createElement('a')
            link.href = this.url
            link.textContent = this.anchorText
            link.style.color = 'blue'
            link.style.textDecoration = 'underline'
            link.style.cursor = 'pointer'
            link.addEventListener('click', event => {
                event.preventDefault()
                window.open(this.url, '_blank')
            })
            editor.focus()
            this.restoreSelection(this.selection)
            document.execCommand('insertHTML', false, '&nbsp' + link.outerHTML)
            this.showModal = false
            this.url = ''
            this.anchorText = ''
        },

        saveSelection() {
            if (window.getSelection) {
                let sel = window.getSelection()
                if (sel.getRangeAt && sel.rangeCount) {
                    var ranges = []
                    for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                        ranges.push(sel.getRangeAt(i))
                    }
                    return ranges
                }
            } else if (document.selection && document.selection.createRange) {
                return document.selection.createRange()
            }
            return null
        },

        restoreSelection(savedSel) {
            if (savedSel) {
                if (window.getSelection) {
                    let sel = window.getSelection()
                    sel.removeAllRanges()
                    for (var i = 0, len = savedSel.length; i < len; ++i) {
                        sel.addRange(savedSel[i])
                    }
                } else if (document.selection && savedSel.select) {
                    savedSel.select()
                }
            }
        },

        updateNews() {
            let content = this.$refs.editor.innerHTML
            let result = this.updateRemaining(content)
            if (result.substractionResult > -1) {
                this.news_content = content
            }
        },

        updateRemaining(newValue) {
            let spaces, content, counter, substractionResult
            spaces = (newValue.match(/&nbsp/g) || []).length
            // links = (newValue.match(/<a/g) || []).length;
            content = this.extractedContent(newValue)
            spaces = (content.match(/&nbsp/g) || []).length
            counter = 500 - (content.length - spaces * 6 + spaces)
            substractionResult = counter
            counter = counter < 0 ? 0 : counter
            this.remainingCharacters = counter
            return {
                remaining: this.remainingCharacters,
                substractionResult: substractionResult,
            }
        },

        // Method for creating and publishing the news
        createNews() {
            this.mutation = require('@/graphql/news/createNews.gql')

            try {
                this.$apollo
                    .mutate({
                        mutation: this.mutation,
                        variables: {
                            news_content: this.news_content,
                            news_date_expiration: moment(
                                this.news_date_expiration
                            ).format('YYYY-MM-DD'),
                            news_hierarchy: 'Deafult',
                            news_type: this.news_type,
                            client: this.$store.getters.userForPortalAccess
                                .client.id,
                        },
                    })
                    .then(({ data }) => {
                        this.$emit('newsCreated', data.createNews)
                        this.snackbar.show = true
                        this.snackbar.message = 'News published successfully'
                        this.snackbar.color = 'success'
                        this.getAllNews()
                        // Reset the editor and hide it
                        this.news_content = ''
                        this.news_date_expiration = null
                        this.news_type = null
                        this.hideNews = false
                        this.editingNews = false
                        this.$refs.editor.innerHTML = ''
                    })
            } catch (error) {
                this.error = error
                this.snackbar.show = true
                this.snackbar.message = 'Error publishing news'
                this.snackbar.color = 'error'
            }

            // Validations for the news content and expiration date
            if (
                this.news_content === '' ||
                this.news_date_expiration === null ||
                this.news_type == null
            ) {
                alert('Please fill all the fields.')
                return
            }
        },
        updateDate(date) {
            // const formattedDate = moment(date).format('MM-DD-YYYY');
            // Actualiza la variable news_date_expiration con la nueva fecha formateada
            this.news_date_expiration = date
            this.showDatePicker = false
        },
        goBack() {
            this.$emit('go-back')
        },
        editNews(news) {
            // Set the selectedNews data property to the selected news
            this.selectedNews = news

            // Set the content of the editor to the selected news content
            this.news_content = news.news_content
            this.$refs.editor.innerHTML = news.news_content

            // Set the expiration date to the selected news date
            this.news_date_expiration = news.news_date_expiration
            // Set the news type to the selected news type
            this.news_type = news.news_type
            // Hide the news list section and show the editor section
            this.hideNews = true
            // Set the editingNews flag to true
            this.editingNews = true
        },
        NewEdit() {
            console.log('entro a new edit')
            // Get the ID of the selected news
            const newsId = this.selectedNews.id
            // Get the updated news content, expiration date, and type from the data properties
            const newsContent = this.news_content
            const newsDateExpiration = moment(this.news_date_expiration).format(
                'YYYY-MM-DD'
            )
            const newsType = this.news_type

            // Use the updateNews mutation to update the selected news
            this.$apollo
                .mutate({
                    mutation: require('@/graphql/news/updateNews.gql'),
                    variables: {
                        id: newsId,
                        news_content: newsContent,
                        news_date_expiration: newsDateExpiration,
                        news_hierarchy: 'Default',
                        news_type: newsType,
                        client: this.$store.getters.userForPortalAccess.client
                            .id,
                    },
                })
                .then(({ data }) => {
                    // Update the allNews data property with the updated news
                    const updatedNewsIndex = this.allNews.findIndex(
                        news => news.id === data.updateNews.id
                    )
                    this.allNews.splice(updatedNewsIndex, 1, data.updateNews)

                    // Show a success message
                    this.snackbar.show = true
                    this.snackbar.message = 'News updated successfully'
                    this.snackbar.color = 'success'

                    // Reset the editor and hide it
                    this.news_content = ''
                    this.news_date_expiration = null
                    this.news_type = null
                    this.hideNews = false
                    this.editingNews = false
                    this.$refs.editor.innerHTML = ''
                })
                .catch(error => {
                    // Show an error message
                    this.error = error
                    this.snackbar.show = true
                    this.snackbar.message = 'Error updating news'
                    this.snackbar.color = 'error'
                })
        },
        deleteNew() {
            this.$apollo
                .query({
                    query: require('@/graphql/news/allNews.gql'),
                    variables: this.news_query_vars(),
                    fetchPolicy: 'network-only', // Garantiza que se realiza una nueva consulta cada vez que se llama al método
                })
                .then(response => {
                    const allNews = response.data.allNews

                    if (allNews.length > 0) {
                        const lastNews = allNews[allNews.length - 1]

                        // Eliminamos la última noticia existente
                        this.$apollo
                            .mutate({
                                mutation: require('@/graphql/news/deleteNews.gql'),
                                variables: {
                                    id: lastNews.id,
                                },
                            })
                            .then(() => {
                                this.snackbar.show = true
                                this.snackbar.message =
                                    'Last news deleted successfully'
                                this.snackbar.color = 'success'
                                this.toggleNews()
                            })
                            .catch(error => {
                                this.error = error
                                this.snackbar.show = true
                                this.snackbar.message =
                                    'Error deleting last news'
                                this.snackbar.color = 'error'
                            })
                    } else {
                        this.snackbar.show = true
                        this.snackbar.message = 'There are no news to delete'
                        this.snackbar.color = 'info'
                    }
                })
                .catch(error => {
                    this.error = error
                    this.snackbar.show = true
                    this.snackbar.message = 'Error getting news list'
                    this.snackbar.color = 'error'
                })
        },
    },
}
</script>

<style>
.editor {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    margin-top: 0px;
    margin-bottom: 10px;
    min-height: 200px;
}

option {
    font-size: 14px;
    /* Tamaño de fuente de 14px */
}

.link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.datepicker {
    margin-left: 8px;
    margin-right: 8px;
    max-width: 30%;
}

@media (max-width: 767px) {
    .datepicker {
        max-width: 100%;
    }

    .select {
        max-width: 100%;
    }
}
</style>
